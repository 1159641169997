<template>

<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15"><path d="m6.125 14.875-.979-1L9 10 5.146 6.125l.979-1L10 9l3.875-3.875.979 1L11 10l3.854 3.875-.979 1L10 11Z"/></svg>
</template>
<script>
export default {
   name: 'closebutton',
}
</script>

<style scoped>

</style>